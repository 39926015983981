import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "border-borderColor border-b-2 text-textHeaderColor text-lg" }
const _hoisted_2 = { class: "flex bg-transparent tab" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: _normalizeClass(["pb-2 me-12 relative cursor-pointer hover:text-primary hover:border-primary", index === _ctx.activeItem ? 'tab-active' : '']),
          onClick: ($event: any) => (_ctx.changeActive(index))
        }, _toDisplayString(_ctx.$t(item)), 11, _hoisted_3))
      }), 128))
    ])
  ]))
}