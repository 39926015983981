import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-lg mb-5" }
const _hoisted_2 = {
  key: 0,
  class: "border rounded mb-5 text-xl text-start p-2"
}
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "border rounded mb-5 p-2" }
const _hoisted_6 = { class: "text-lg" }
const _hoisted_7 = { class: "list-decimal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multiselect = _resolveComponent("multiselect")!
  const _component_FieldWrapper = _resolveComponent("FieldWrapper")!
  const _component_StripeElement = _resolveComponent("StripeElement")!
  const _component_StripeElements = _resolveComponent("StripeElements")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.$t('settings.paymentsTitle')), 1),
    (_ctx.branchesUnlinked && _ctx.branchesUnlinked.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('settings.unlinkedBranches')), 1),
          _createElementVNode("ol", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.branchesUnlinked, (branch, index) => {
              return (_openBlock(), _createElementBlock("li", {
                class: "p-2 ms-5 list-decimal",
                key: index
              }, _toDisplayString(branch.name), 1))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.$t('settings.yourCards')), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (card, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "border rounded mb-5 p-2",
          key: index
        }, [
          _createElementVNode("div", null, "**** **** **** " + _toDisplayString(card.last4), 1),
          _createElementVNode("div", null, [
            _createVNode(_component_FieldWrapper, {
              label: "settings.paymentsBranches",
              name: "paymentsBranches"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_multiselect, {
                  multiple: true,
                  closeOnSelect: false,
                  options: _ctx.$store.state.branches,
                  modelValue: card.branches,
                  "onUpdate:modelValue": ($event: any) => ((card.branches) = $event),
                  onSelect: ($event: any) => (_ctx.setBranchToAdd($event, card)),
                  trackBy: "_id",
                  label: "name",
                  id: "paymentsBranches",
                  placeholder: _ctx.$t('selectOption'),
                  deselectLabel: _ctx.$t('deselect'),
                  selectedLabel: _ctx.$t('selected'),
                  selectLabel: _ctx.$t('select')
                }, null, 8, ["options", "modelValue", "onUpdate:modelValue", "onSelect", "placeholder", "deselectLabel", "selectedLabel", "selectLabel"])
              ]),
              _: 2
            }, 1024)
          ]),
          _createElementVNode("button", {
            type: "button",
            class: "danger",
            onClick: ($event: any) => (_ctx.removeCard(card))
          }, _toDisplayString(_ctx.$t('remove')), 9, _hoisted_4)
        ]))
      }), 128)),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.stripeLoaded)
          ? (_openBlock(), _createBlock(_component_StripeElements, {
              key: 0,
              class: "mb-3 p-2",
              ref: "elms",
              "stripe-key": _ctx.stripeKey,
              "instance-options": _ctx.instanceOptions,
              "elements-options": _ctx.elementsOptions
            }, {
              default: _withCtx(({ elements }) => [
                _createVNode(_component_StripeElement, {
                  ref: "card",
                  elements: elements,
                  options: _ctx.cardOptions
                }, null, 8, ["elements", "options"])
              ]),
              _: 1
            }, 8, ["stripe-key", "instance-options", "elements-options"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createVNode(_component_FieldWrapper, {
            label: "settings.paymentsBranches",
            name: "paymentsBranches"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_multiselect, {
                multiple: true,
                taggable: true,
                closeOnSelect: false,
                modelValue: _ctx.branchesToAdd,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.branchesToAdd) = $event)),
                onSelect: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setBranchToAdd($event, null))),
                options: _ctx.$store.state.branches,
                label: "name",
                trackBy: "_id",
                id: "paymentsBranches",
                deselectLabel: _ctx.$t('deselect'),
                selectedLabel: _ctx.$t('selected'),
                selectLabel: _ctx.$t('select'),
                placeholder: _ctx.$t('selectOption')
              }, null, 8, ["modelValue", "options", "deselectLabel", "selectedLabel", "selectLabel", "placeholder"])
            ]),
            _: 1
          })
        ]),
        _createElementVNode("button", {
          type: "button",
          class: "primary",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.prepareToSaveCard && _ctx.prepareToSaveCard(...args)))
        }, _toDisplayString(_ctx.$t('save')), 1)
      ])
    ]),
    (_ctx.removeBranchConfirmation)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 1,
          title: "settings.removeBranchConfirmation",
          onClosed: _ctx.clearRemoving
        }, {
          footer: _withCtx(() => [
            _createElementVNode("button", {
              type: "button",
              class: "primary",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.removeBranch && _ctx.removeBranch(...args)))
            }, _toDisplayString(_ctx.$t('yes')), 1)
          ]),
          _: 1
        }, 8, ["onClosed"]))
      : _createCommentVNode("", true),
    (_ctx.showCantRemoveCardModal)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 2,
          title: "settings.cantRemoveCard",
          hideCancel: true,
          onClosed: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showCantRemoveCardModal = false))
        }, {
          footer: _withCtx(() => [
            _createElementVNode("button", {
              type: "button",
              class: "primary",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showCantRemoveCardModal = false))
            }, _toDisplayString(_ctx.$t('ok')), 1)
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('settings.assignBranchesToAnotherCard')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.addBranchConfirmation)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 3,
          title: "settings.addBranchConfirmation",
          onClosed: _ctx.clearAdding
        }, {
          footer: _withCtx(() => [
            _createElementVNode("button", {
              type: "button",
              class: "primary",
              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.addBranch && _ctx.addBranch(...args)))
            }, _toDisplayString(_ctx.$t('yes')), 1)
          ]),
          _: 1
        }, 8, ["onClosed"]))
      : _createCommentVNode("", true),
    (_ctx.askLinkedList && _ctx.askLinkedList.length > 0)
      ? (_openBlock(), _createBlock(_component_Modal, {
          key: 4,
          title: "settings.saveCardConfirmation",
          onClosed: _cache[8] || (_cache[8] = ($event: any) => (_ctx.askLinkedList = []))
        }, {
          footer: _withCtx(() => [
            _createElementVNode("button", {
              type: "button",
              class: "primary",
              onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.saveCard && _ctx.saveCard(...args)))
            }, _toDisplayString(_ctx.$t('yes')), 1)
          ]),
          default: _withCtx(() => [
            _createElementVNode("ol", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.askLinkedList, (linked, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  class: "text-xl p-2 ms-5 text-left",
                  key: index
                }, _toDisplayString(linked.name), 1))
              }), 128))
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}