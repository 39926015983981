
import { defineComponent } from 'vue'
import Multiselect from 'vue-multiselect'
import { loadStripe } from '@stripe/stripe-js'
import { StripeElements, StripeElement } from 'vue-stripe-js'
import { Branch } from '@/shared/interfaces/branch.model'
import { Card } from '@/shared/interfaces/card.model'
import FieldWrapper from '@/shared/components/FieldWrapper.vue'
import Modal from '@/shared/components/Modal.vue'

export default defineComponent({
  name: 'Payments',
  components: {
    FieldWrapper,
    Modal,
    Multiselect,
    StripeElements,
    StripeElement
  },
  data: function () {
    return {
      branchesToAdd: [] as Branch[],
      stripeLoaded: false as boolean,
      stripeKey: process.env.VUE_APP_STRIPE_KEY,
      instanceOptions: {} as any,
      elementsOptions: {} as any,
      cardOptions: {} as any,
      removeBranchConfirmation: false as boolean,
      addBranchConfirmation: false as boolean,
      cardToRemoveBranch: null as null | any,
      cardToAddBranch: null as null | any,
      branchToRemove: null as null | Branch,
      branchToAdd: null as null | Branch,
      askLinkedList: [] as Branch[],
      showCantRemoveCardModal: false as boolean
    }
  },
  beforeMount () {
    const stripePromise = loadStripe(this.stripeKey)
    stripePromise.then(() => {
      this.stripeLoaded = true
    })
  },
  computed: {
    cards (): Array<Card> {
      return this.$store.state.cards.map((card: any) => {
        card.branches = this.$store.state.branches.filter((branch: Branch) => card.branchesIds.indexOf(branch._id as string) >= 0)
        if (!card.branches || card.branches.length === 0) card.branches = []
        return card
      })
    },
    branchesUnlinked (): Array<Branch> {
      return this.$store.state.branches.filter((branch: Branch) => !this.cards.find((card: Card) => card.branchesIds.indexOf(branch._id as string) >= 0))
    }
  },
  methods: {
    prepareToSaveCard () {
      (this.branchesToAdd as Branch[]).forEach((branch: Branch) => {
        const isUnlinked = this.branchesUnlinked.find((unlinkedBranch: Branch) => unlinkedBranch._id === branch._id)
        if (!isUnlinked) this.askLinkedList.push(branch)
      })
      if (this.askLinkedList.length === 0) {
        this.saveCard()
      }
    },
    saveCard () {
      if (this.$refs) {
        const cardElement: any = (this.$refs.card as any).stripeElement
        const elms = this.$refs.elms as any
        elms.instance.createToken(cardElement).then((result: any) => {
          if (result.token) {
            this.$store.dispatch('addCardDetails', {
              tokenId: result.token.id,
              last4: result.token.card.last4,
              branchesIds: (this.branchesToAdd as Branch[]).map((branch: Branch) => branch._id)
            })
            cardElement.clear()
            this.branchesToAdd = []
          }
        })
      }
    },
    removeCard (card: Card) {
      if (card.branchesIds.length === 0) this.$store.dispatch('deleteCard', card._id)
      else this.showCantRemoveCardModal = true
    },
    setBranchToAdd (branch: Branch, card: Card | null) {
      this.cardToAddBranch = card
      this.branchToAdd = branch
      const isUnlinked = this.branchesUnlinked.find((unlinkedBranch: Branch) => unlinkedBranch._id === branch._id)
      if (!card) {
        const index = this.branchesToAdd.indexOf(branch)
        if (index >= 0) this.branchesToAdd.splice(index, 1)
      }
      if (!isUnlinked) {
        this.addBranchConfirmation = true
      } else this.addBranch()
    },
    setBranchToRemove (branch: Branch, card: Card) {
      this.cardToRemoveBranch = card
      this.branchToRemove = branch
      this.removeBranchConfirmation = true
    },
    addBranch () {
      let branches: any[]
      if (this.cardToAddBranch) branches = this.cardToAddBranch.branchesIds
      else branches = this.branchesToAdd
      if (this.branchToAdd) {
        this.cards.forEach((card: any) => {
          const index = card.branchesIds.indexOf(this.branchToAdd?._id as string)
          if (index >= 0) card.branchesIds.splice(index, 1)
        })
        if (this.cardToAddBranch) branches.push(this.branchToAdd._id as string)
        else branches.push(this.branchToAdd)
      }
      this.addBranchConfirmation = false
      if (this.cardToAddBranch) this.$store.dispatch('updateCard', { _id: this.cardToAddBranch._id, branchesIds: branches })
    },
    clearAdding () {
      this.cardToAddBranch = null
      this.branchToAdd = null
      this.addBranchConfirmation = false
    },
    removeBranch () {
      if (this.cardToRemoveBranch && this.branchToRemove) {
        const index = this.cardToRemoveBranch.branchesIds.indexOf(this.branchToRemove._id as string)
        if (index >= 0) this.cardToRemoveBranch.branchesIds.splice(index, 1)
      }
      this.removeBranchConfirmation = false
    },
    clearRemoving () {
      this.cardToRemoveBranch = null
      this.branchToRemove = null
      this.removeBranchConfirmation = false
    }
  }
})
