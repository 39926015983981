
import { defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  name: 'Tab',
  props: {
    tabItems: Array as PropType<Array<string>>,
    activeTab: String
  },
  setup (props, { emit }) {
    const items = ref(props.tabItems || [])
    const activeItem = ref(0)

    const changeActive = (indexOf: number): void => {
      activeItem.value = indexOf
      emit('tabChanged', items.value[indexOf])
    }

    return {
      items,
      changeActive,
      activeItem
    }
  },
  beforeMount () {
    if (this.activeTab) this.activeItem = (this.tabItems as Array<string>).indexOf(this.activeTab)
  }
})
