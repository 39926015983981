
import { defineComponent, reactive } from 'vue'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { VueCookieNext } from 'vue-cookie-next'
import { required, sameAs, email } from '@/customValidators'
import PageTitle from '@/shared/components/PageTitle.vue'
import FieldWrapper from '@/shared/components/FieldWrapper.vue'
import Tab from '@/shared/components/Tab.vue'
import { Branch } from '@/shared/interfaces/branch.model'
import IndicatorIcon from '@/shared/components/base-icon/icons/IndicatorIcon.vue'
import Modal from '@/shared/components/Modal.vue'
import Payments from '@/shared/components/Payments.vue'
import { Card } from '@/shared/interfaces/card.model'
import VueMultiselect from 'vue-multiselect'

export default defineComponent({
  name: 'Settings',
  components: {
    PageTitle,
    FieldWrapper,
    IndicatorIcon,
    Tab,
    Modal,
    Payments,
    VueMultiselect
  },
  setup: () => {
    const store = useStore()
    store.commit('setVuelidateExternalResults', {})
    return { v$: useVuelidate({ $externalResults: reactive(store.state.vuelidateExternalResults), $autoDirty: true }) }
  },
  data: function () {
    type SelectedCard = {
      display: string
      value: string
    } | null
    return {
      tabItems: ['settings.userData', 'settings.branches', 'settings.reset', 'settings.payments'],
      selectedCard: null as SelectedCard | null,
      activeTab: 'settings.userData',
      showResetModal: undefined as string | undefined,
      showAddAccessModal: undefined as string | undefined,
      user: {
        company: '' as string,
        branches: [] as Branch[]
      },
      isLoading: false as boolean,
      resetForm: {
        password: '',
        password_confirm: ''
      },
      resetAccessForm: {
        password: '',
        password_confirm: ''
      },
      addAccessForm: {
        accessEmail: '',
        password: '',
        password_confirm: ''
      },
      addBranchForm: {
        name: '',
        card: {
          last4: '',
          _id: ''
        }
      }
    }
  },
  validations: function () {
    return {
      user: {
        company: { required },
        branches: this.getBranchesValidations
      },
      resetForm: {
        password: { required },
        password_confirm: { required, sameAs: sameAs(this.resetForm.password) }
      },
      resetAccessForm: {
        password: { required },
        password_confirm: { required, sameAs: sameAs(this.resetAccessForm.password) }
      },
      addAccessForm: {
        accessEmail: { required, email },
        password: { required },
        password_confirm: { required, sameAs: sameAs(this.addAccessForm.password) }
      },
      addBranchForm: {
        name: { required }
      }
    }
  },
  beforeMount () {
    this.fillData()
    this.$store.dispatch('getLoggedUser').then(() => this.fillBranches())
    this.$store.dispatch('getCardsDetails')
    if (this.$route.query.section) this.activeTab = 'settings.' + this.$route.query.section
    this.initActiveTab()
    if (
      this.$store.state.loggedUser &&
      this.$store.state.loggedUser.branches &&
      this.$store.state.loggedUser.branches.length > 0 &&
      this.$store.state.loggedUser.branches[this.$store.state.loggedUser.branches.length - 1].name.indexOf(this.$store.state.loggedUser.companyName + '-') === 0
    ) this.addBranchForm.name = this.$store.state.loggedUser.companyName + '-'
    else this.addBranchForm.name = ''
  },
  computed: {
    cards (): Array<Card> {
      return this.$store.state.cards.map((card: any) => {
        card.branches = this.$store.state.branches.filter((branch: Branch) => card.branchesIds.indexOf(branch._id as string) >= 0)
        if (!card.branches || card.branches.length === 0) card.branches = []
        return card
      })
    },
    getBranchesValidations () {
      return this.$store.state.loggedUser && this.$store.state.loggedUser.branches ? this.$store.state.loggedUser.branches.map((branch: Branch) => {
        return {
          name: { required },
          accesses: branch.accesses.map(() => ({ email: { required, email } }))
        }
      }) : []
    }
  },
  methods: {
    fillData () {
      this.user.company = this.$store.state.loggedUser ? this.$store.state.loggedUser.companyName : ''
    },
    cardLabel ({ last4 }: any) {
      return last4 ? `**** **** **** ${last4}` : ''
    },
    fillBranches () {
      this.user.branches = this.$store.state.loggedUser && this.$store.state.loggedUser.branches ? JSON.parse(JSON.stringify(this.$store.state.loggedUser.branches)) : []
    },
    async save () {
      const toValidate = this.activeTab === this.tabItems[0] ? 'company' : 'branches'
      const isFormCorrect = await this.v$.user[toValidate].$validate()
      if (!isFormCorrect) return
      if (this.activeTab === this.tabItems[0]) this.$store.state.loggedUser.companyName = this.user.company
      else this.$store.state.loggedUser.branches = JSON.parse(JSON.stringify(this.user.branches))
      this.isLoading = true
      await this.$store.dispatch('updateUser').then(() => {
        this.isLoading = false
        this.v$.user[toValidate].$reset()
        this.fillData()
        this.fillBranches()
      }).catch(() => {
        this.isLoading = false
      })
    },
    cancel () {
      this.fillData()
      this.fillBranches()
    },
    async reset () {
      const isFormCorrect = await this.v$.resetForm.$validate()
      const token = VueCookieNext.getCookie('token')
      if (!isFormCorrect || !token) return
      this.$store.dispatch('reset', { email: this.$store.state.loggedUser.email, token, ...this.resetForm }).then(() => this.$store.dispatch('logout'))
    },
    cancelResetAccess () {
      this.resetAccessForm.password = ''
      this.resetAccessForm.password_confirm = ''
      this.showResetModal = undefined
      this.v$.resetAccessForm.$reset()
    },
    async resetAccess (_id: string, branchId: string) {
      const isFormCorrect = await this.v$.resetAccessForm.$validate()
      const token = VueCookieNext.getCookie('token')
      if (!isFormCorrect || !token) return
      this.$store.dispatch('resetAccess', { _id, branch_id: branchId, ...this.resetAccessForm }).then(() => {
        this.cancelResetAccess()
      })
    },
    deleteAccess (accessId: string, branchId: string) {
      this.$store.dispatch('deleteAccess', { access_id: accessId, branch_id: branchId }).then(() => this.cancel())
    },
    cancelAddAccess () {
      this.addAccessForm.accessEmail = ''
      this.addAccessForm.password = ''
      this.addAccessForm.password_confirm = ''
      this.showAddAccessModal = undefined
      this.v$.addAccessForm.$reset()
      this.cancel()
    },
    async addAccess (branchId: string) {
      const isFormCorrect = await this.v$.addAccessForm.$validate()
      const token = VueCookieNext.getCookie('token')
      if (!isFormCorrect || !token) return
      this.$store.dispatch('addAccess', { branch_id: branchId, ...this.addAccessForm }).then(() => this.cancelAddAccess())
    },
    async addBranch () {
      const isFormCorrect = await this.v$.addBranchForm.$validate()
      const token = VueCookieNext.getCookie('token')
      if (!isFormCorrect || !token) return
      await this.$store.dispatch('addBranch', { name: this.addBranchForm.name, cardId: this.addBranchForm.card._id })
      if (
        this.$store.state.loggedUser &&
        this.$store.state.loggedUser.branches &&
        this.$store.state.loggedUser.branches.length > 0 &&
        this.$store.state.loggedUser.branches[this.$store.state.loggedUser.branches.length - 1].name.indexOf(this.$store.state.loggedUser.companyName + '-') === 0
      ) this.addBranchForm.name = this.$store.state.loggedUser.companyName + '-'
      else this.addBranchForm.name = ''
      this.v$.addBranchForm.$reset()
      this.fillBranches()
    },
    deleteBranch (_id: string) {
      this.$store.dispatch('deleteBranch', { _id }).then(() => this.cancel())
    },
    // suspendBranch (_id: string) {
    //   this.$store.dispatch('suspendBranch', _id).then(() => this.cancel())
    // },
    changedTab (tabName: string) {
      if (tabName === 'settings.payments' || tabName === 'settings.branches') this.$store.dispatch('getCardsDetails')
      this.activeTab = tabName
      this.updateSectionQueryParam(tabName)
    },
    updateSectionQueryParam (tabName: string) {
      this.$router.push({ path: '/app/settings', query: { section: tabName } })
    },
    initActiveTab () {
      const sectionQuery = this.$route.query.section as string
      if (sectionQuery) this.activeTab = sectionQuery.toString()
      else this.activeTab = this.tabItems[0]
    }
  }
})
